@import '@/pages/va.module';
.index {
    background: #F7F8FA;
    .blogNew {
        padding: 100px 0 60px;
      
        .title {
            font-size: 48px;
            line-height: 72px;
            font-family: 'Noto Sans Display Blod';
            font-weight: bold;
            color: #0E1527;
            text-align: center;
            margin-bottom: 104px;
        }

        .blogList {
            display: grid;
            margin-top: 20px;
            justify-content: space-between;
            // grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
            // repeat(4, 1fr)
            grid-template-columns: repeat(4, calc((100% - 40px) / 4));
            gap: 20px;
            .item {
              display: flex;
              flex-direction: column;
              border-radius: 16px;
              overflow: hidden;
              background-color: #fff;
              max-width: 335px;
              transition: transform 0.3s;
              &:hover {
                transform: translateY(-10px);
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
              }
            }
            .imgBox {
              width: 335px;
              height: 234px;
              object-fit: cover;
            }
            .itemInfo {
              display: flex;
              flex-direction: column;
              padding: 16px 16px 0px;
              .title {
                text-align: left;
                font-size: 16px;
                font-family: "Noto Sans Display Blod";
                font-weight: 600;
                color: #2e384e;
                line-height: 25px;
                text-overflow: ellipsis;
                overflow: hidden;
                // word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                /** 对象作为伸缩盒子模型显示 **/
                -webkit-box-orient: vertical;
                /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                -webkit-line-clamp: 2;
                /** 显示的行数 **/
                min-height: 50px;
                margin: 0;
              }
      
              .time {
                margin-top: 12px;
                font-size: 14px;
                color: #b8c0cb;
                line-height: 21px;
              }
            }
            .itemBottom {
              padding: 0px 16px 27px;
              .desc {
                margin-top: 12px;
                font-size: 12px;
                color: #708096;
                line-height: 17px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                /** 对象作为伸缩盒子模型显示 **/
                -webkit-box-orient: vertical;
                /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                -webkit-line-clamp: 3;
                min-height: 51px;
              }
            }
          }
    }

    .mNews {
        display: none;
        padding-left: 20px;
        position: relative;

        .myBullet {
            width: 20px !important;
            height: 3px !important;
            border-radius: 0px !important;
            // background-color: rgba($color: #000000, $alpha: 0.3);
            margin: 0px 5px !important;

            &:first-child {
                border-top-left-radius: 2px !important;
                border-bottom-left-radius: 2px !important;
            }

            &:last-child {
                border-top-right-radius: 2px !important;
                border-bottom-right-radius: 2px !important;
            }
        }

        .myActiveBullet {
            // width: 100px !important;
            background-color: #1F2226 !important;
        }

        .myPagination {
            position: absolute;

        }

        .titleh2 {
            font-size: 24px;
            // font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #0A122C;
            line-height: 35px;
            margin: 36px 0;
            padding-right: 20px;
            text-align: center;
        }

        .more {
            // font-size: 14px;
            // // font-family: PingFangSC-Medium, PingFang SC;
            // font-weight: 500;
            // color: #FF3E5F;
            // line-height: 20px;
            // text-align: center;
            // display: block;
            // margin: 20px 0;
            justify-content: center;
            margin: 24px auto;
            width: 140px;
            height: 40px;
            border-radius: 20px;
            font-size: 16px;
            border: 1px solid #FF3E5F;
            color: #FF3E5F;
            text-align: center;
        }

        .blog {
            width: 300px;
            height: 323px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #EEEEEE;
            display: block;

            .imgs {
                width: 300px;
                height: 172px;
                // background: linear-gradient(180deg, rgba(80, 192, 226, 0) 0%, #50C0E2 100%);
                // border-radius: 8px 8px 0px 0px;


                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px 8px 0px 0px;
                    border: 1px solid #EEEEEE;
                    border-bottom: none;
                    overflow: hidden;
                    margin: -1px;
                }
            }

            .infos {
                padding: 16px 16px 32px;
                height: 100%;

                .title {
                    font-size: 16px;
                    // font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #0A122C;
                    line-height: 30px;
                    height: 60px;
                }

                .desc {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #727275;
                    line-height: 17px;
                    height: 34px;
                }
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .index {
        .blogNew {
            display: none;
        }

        .mNews {
            display: block;
        }
    }
}